import { ApiService } from '@core/service/api.service';
import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class UserService {
    constructor(private readonly _apiService: ApiService) {}

    public getUser(id: string, type?: string): Observable<any> {
        if (type === 'draft') {
            return this._apiService.get(`users/${type}/${id}`);
        }

        return this._apiService.get(`users/${id}`, null, true);
    }

    public process(data: any[]): Observable<any> {
        return this._apiService.post('users/migration/publish', data);
    }

    public publish(data: any): Observable<any> {
        return this._apiService.post('users/migration/publish', data);
    }

    public getRoles(): Observable<any> {
        return this._apiService.get('lovs/roles');
    }

    public getUsers(type?: string, params?: HttpParams): Observable<any> {
        const base: string = 'users';

        if (type === 'draft') {
            return this._apiService.get(`${base}/draft`, params);
        }

        return this._apiService.get(base, params);
    }

    public saveUser(data: any, type: string): Observable<any> {
        return this._apiService.post(`users/${type}`, data, true);
    }

    public assignRole(data: any): Observable<any> {
        return this._apiService.put('users/draft/assign-role', data, true);
    }

    public deleteUser(type: string, id: string): Observable<any> {
        return this._apiService.delete(`users/${type}/${id}`, true);
    }

    public updateUser(data: any): Observable<any> {
        return this._apiService.put('users/draft', data, true);
    }

    public verifyEmail(token: string): Observable<any> {
        return this._apiService.get(`users/verify?token=${token}`);
    }

    public toggleStatus(type: string, id: string): Observable<any> {
        return this._apiService.put(`users/${type}/${id}`, null, true);
    }

    public createPassword(data: any): Observable<any> {
        return this._apiService.put('users/create-password', data);
    }

    public resetPassword(data: any): Observable<any> {
        return this._apiService.put('users/reset-password', data);
    }

    public deleteBulkUsers(type: string, data: any): Observable<any> {
        return this._apiService.deleteAll(`users/${type}`, data, true);
    }

    public uploadTemplate(data: any): Observable<any> {
        return this._apiService.postFormData(
            'users/migration/draft',
            data,
            true
        );
    }

    public getDraftedUsers(): Observable<any> {
        return this._apiService.get('users', null, true);
    }

    public downloadTemplate(): Observable<any> {
        return this._apiService.get('users/migration/template', null, true);
    }

    public downloadMasterReport(): Observable<any> {
        return this._apiService.get('users/master-report', null, true);
    }

    public getUserEffectiveTypes(): Observable<any> {
        return this._apiService.get('users/effective-type');
    }

    public getEmployeeJobHistory(id: string): Observable<any> {
        return this._apiService.get(
            `employee-job-histories/current?employeeId=${id}`
        );
    }

    public resendVerificationToken(token: string): Observable<any> {
        return this._apiService.get(
            `users/resend-verification-token?token=${token}`,
            null
        );
    }

    public downloadDraftUnprocessed(): Observable<any> {
        return this._apiService.get('users/draft/unprocessed', null, true);
    }

    public sendResetPassword(body: any): Observable<any> {
        return this._apiService.post(
            'users/send-reset-password-link',
            body,
            true
        );
    }

    public sendResetDevice(id: any): Observable<any> {
        return this._apiService.put(`users/reset-device/${id}`, null, true);
    }

    public sendResetDeviceBulk(body: any): Observable<any> {
        return this._apiService.put(`users/reset-device`, body, true);
    }

    public verifyResetToken(token: string): Observable<any> {
        return this._apiService.get(`users/verify-reset-token?token=${token}`);
    }

    public bulkResendVerificationLink(data: string[]): Observable<any> {
        return this._apiService.post(
            'users/bulk-resend-verification-link',
            data
        );
    }

    public resendVerificationLink(id: string): Observable<any> {
        return this._apiService.post(
            `users/resend-verification-link/${id}`,
            null
        );
    }

    public unblockUsers(data: string[]): Observable<any> {
        return this._apiService.put('users/blocked', data);
    }
}
