export const environment = {
  production: true,
  apiUrl:"https://api-hcms.krm.co.id/api/v1/",
  apiUrlEss:"https://api-ess.krm.co.id/api/v1/",
  loginUrl:"https://api-hcms.krm.co.id/api/v1/",
  isSSO:false,
  linovWordpress:'https://hcms.krm.co.id/login/',
  webSocket: 'https://api-hcms.krm.co.id/socket',
  webSocketEss: 'https://api-ess.krm.co.id/api/v1/mobile/socket',
  urlJobportal: 'https://jobportal.krm.co.id/',
};
