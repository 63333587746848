import { Title } from '@angular/platform-browser';
import { UserService } from '@workbench/user-2/user.service';
import { HelperService } from '@core/service/helper-service';
import { MessageService } from 'primeng/api';
import { ActivatedRoute } from '@angular/router';
import { MessageBoxService } from '@core/service/message-box.service';
import { OnInit, Component, ChangeDetectorRef } from '@angular/core';
import {
    FormGroup,
    Validators,
    FormBuilder,
    ValidatorFn,
    AbstractControl,
} from '@angular/forms';

@Component({
    selector: 'l-user-create-password',
    styleUrls: ['./create-password.component.scss'],
    templateUrl: './create-password.component.html',
})
export class UserCreatePassword implements OnInit {
    private _token: string = this._activatedRoute.snapshot.queryParams.token;

    public data!: any;
    public isFail!: boolean | undefined;
    public isError!: boolean | undefined;
    public loading!: boolean | undefined;
    public isSuccess!: boolean | undefined;
    public formGroup!: FormGroup<any> | undefined;
    public submitted!: boolean | undefined;
    public resendLink!: boolean | undefined;
    public isSubmitted!: boolean | undefined;
    public isValidPassword!: boolean | undefined;

    constructor(
        private readonly _title: Title,
        private readonly _formBuilder: FormBuilder,
        private readonly _userService: UserService,
        private readonly _activatedRoute: ActivatedRoute,
        private readonly _messageBoxService: MessageBoxService,
        private readonly _changeDetectorRef: ChangeDetectorRef
    ) {}

    private _matchValidator(
        controlName: string,
        matchingControlName: string
    ): ValidatorFn {
        return (abstractControl: AbstractControl) => {
            const control: any = abstractControl.get(controlName);
            const matchingControl: any =
                abstractControl.get(matchingControlName);

            if (
                matchingControl!.errors &&
                !matchingControl!.errors?.['confirmedValidator']
            ) {
                return null;
            }

            if (control!.value !== matchingControl!.value) {
                const error = {
                    confirmedValidator: 'Password confirmation does not match',
                };

                matchingControl!.setErrors(error);

                return error;
            } else {
                matchingControl!.setErrors(null);

                return null;
            }
        };
    }

    private _setupForm(): void {
        const ctrl: any = this._formBuilder.control.bind(this);

        this.formGroup = this._formBuilder.group<any>(
            {
                password: ctrl(null, [Validators.required, Validators.min(8)]),
                confirmPassword: ctrl(null, [Validators.required]),
            },
            {
                validators: this._matchValidator('password', 'confirmPassword'),
            }
        );
    }

    public handleChangeInputPassword(): void {
        this.submitted = false;
    }

    public handleResendVerificationToken(): void {
        this._userService.resendVerificationToken(this._token).subscribe(
            () => (this.resendLink = true),
            () => this._messageBoxService.showError('test', 'test')
        );
    }

    public handleSavePassword(): void {
        this.submitted = true;

        if (this.formGroup.invalid) {
            return this.formGroup.markAllAsTouched();
        } else {
            if (!this.isSubmitted) {
                this.isSubmitted = true;
            }

            this.isFail = this.submitted && !this.isValidPassword;
        }

        if (!this.isFail) {
            this._userService
                .createPassword({
                    token: this._token,
                    password: this.formGroup.get('password').value,
                })
                .subscribe(() => {
                    this.isSuccess = true;

                    setTimeout(() => {
                        window.location.href = `${window.origin}/login`;
                    }, 2500);
                });
        }
    }

    public handleValidPasswordInfo(value: boolean): void {
        this.isValidPassword = value;

        if (this.isValidPassword) {
            this.isFail = false;
        }

        this._changeDetectorRef.detectChanges();
    }

    public verifyEmail(): void {
        this.loading = true;

        this._userService.verifyEmail(this._token).subscribe(
            (res: any) => {
                this.data = res.data;

                this._setupForm();
            },
            (err: any) => {
                this.isError = true;
                this.loading = false;
            }
        );
    }

    public ngOnInit(): void {
        this._title.setTitle('Create Password | LinovHR');

        this.verifyEmail();
    }
}
