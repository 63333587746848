<div
    class="fixed"
    style="
        min-height: 100vh;
        width: 100vw;
        display: grid;
        place-items: center;
        padding: 30px;
        background: linear-gradient(180deg, #48c8ff 14.55%, #0e8ec5 100%);
    "
>
    <img
        alt="Ellipse shape"
        src="assets/images/create-password-ellipse.svg"
        class="ellipse"
    />

    <div
        class="relative card"
        [ngStyle]="{
            maxWidth: isError ? '512px' : null
        }"
    >
        <div
            class="flex justify-content-center align-items-center"
            style="min-height: 353.25px"
            *ngIf="loading && !data"
        >
            <i
                class="fas fa-circle-notch fa-spin fa-3x"
                style="color: var(--primary-blue); opacity: 0.6"
            ></i>
        </div>

        <l-user-create-password-error *ngIf="!loading && isError">
        </l-user-create-password-error>

        <div
            class="flex flex-column justify-content-center align-items-center"
            *ngIf="data && data.expired"
        >
            <img
                *ngIf="resendLink"
                src="assets/images/create-password-mail.svg"
                alt="Illustrator resend link create password"
            />

            <img
                *ngIf="!resendLink"
                src="assets/images/create-password-sorry.svg"
                alt="Illustrator expired link create password"
            />

            <h2
                class="text-center font-bold"
                style="margin: 20px 0 12px; font-size: 24px"
            >
            {{'oopsLinkExpired' | translate}}
            </h2>

            <p class="text-center mt-2 mb-0">
                {{'yourPasswordCreationLinkIsNoLongerValid' | translate}}. <br />
                {{'butItsOkayJustRequestANewOneBelow' | translate}}.
            </p>

            <button
                class="block w-full mt-4 font-bold border-none text-center white-space-nowrap btn"
                [disabled]="resendLink"
                (click)="handleResendVerificationToken()"
            >
                <i
                    class="fa-solid fa-check"
                    style="margin-right: 10px"
                    *ngIf="resendLink"
                ></i>
                <span>{{"requestLink" | translate}}</span>
            </button>
        </div>

        <ng-container *ngIf="data && !data.expired">
            <div class="text-center" style="margin-bottom: 24px">
                <h1
                    class="font-bold mb-2"
                    style="color: #4b4b4b; font-size: 24px"
                >
                    <i class="fa-solid fa-key mr-3"></i>
                    <span>{{"createPassword" | translate}}</span>
                </h1>
                <p class="font-bold mb-2" style="color: #656565">
                    Welcome, {{ data?.employeeName }}!
                </p>
                <p class="mb-0">
                    {{"pleaseCreatePasswordToAccessYourAccount" | translate}}.
                </p>
            </div>

            <div
                *ngIf="
                    formGroup.get('password').value &&
                    formGroup.get('password').touched &&
                    isFail &&
                    submitted
                "
                style="
                    margin-bottom: 24px;
                    border-radius: 4px;
                    padding: 6px 16px;
                    background: #ffe8e8;
                    color: #d85454;
                    text-align: center;
                "
            >
                <i
                    class="fa-solid fa-circle-exclamation"
                    style="margin-right: 12px"
                ></i>
                <span>{{"passwordDoesNotMeetRequirements"| translate}}</span>
            </div>

            <div *ngIf="isSuccess" style="margin-bottom: 26px">
                <div
                    class="flex justify-content-center align-items-center"
                    style="
                        gap: 12px;
                        margin-bottom: 8px;
                        border-radius: 4px;
                        padding: 6px 16px;
                        background: #d5fede;
                        color: #54b486;
                    "
                >
                    <i class="fa-solid fa-circle-check"></i>
                    <span>{{ "successfullyCreatedPassword" | translate}}</span>
                </div>
                <div
                    class="text-center"
                    style="color: #656565; font-size: 12px"
                >
                    <i
                        class="fas fa-spinner fa-pulse"
                        style="color: #9f9f9f"
                    ></i>
                    <span style="margin-left: 8px"
                        >{{"redirectingYouToLoginPage" | translate}}...</span
                    >
                </div>
            </div>

            <form
                [ngStyle]="{
                    opacity: isSuccess ? 0.6 : 1
                }"
                [formGroup]="formGroup"
            >
                <div class="mb-4 block">
                    <label
                        label
                        for="password"
                        class="required block"
                        style="margin-bottom: 10px"
                        >{{ "password" | translate }}</label
                    >

                    <input-password
                        contentField
                        id="password"
                        formControlName="password"
                        [showInfo]="false"
                        [showGenerator]="false"
                        (onChange)="handleChangeInputPassword($event)"
                    ></input-password>

                    <app-error-field-helper
                        helper
                        [formControlKey]="formGroup.get('password')"
                    ></app-error-field-helper>

                    <l-user-password-info
                        class="block"
                        style="margin-top: 12px"
                        *ngIf="
                            formGroup.get('password').value &&
                            formGroup.get('password').touched
                        "
                        [value]="formGroup.get('password').value"
                        [isSubmitted]="isSubmitted"
                        (valid)="handleValidPasswordInfo($event)"
                    ></l-user-password-info>
                </div>
                <div>
                    <label
                        label
                        for="confirmPassword"
                        class="required block"
                        style="margin-bottom: 10px"
                        >{{ "confirmPassword" | translate }}</label
                    >

                    <input-password
                        contentField
                        id="confirmPassword"
                        formControlName="confirmPassword"
                        [showInfo]="false"
                        [showGenerator]="false"
                    ></input-password>

                    <app-error-field-helper
                        helper
                        [formControlKey]="formGroup.get('confirmPassword')"
                    ></app-error-field-helper>
                </div>

                <div class="mt-5">
                    <button
                        class="block w-full font-bold border-none text-center white-space-nowrap btn"
                        [disabled]="isSuccess"
                        (click)="handleSavePassword()"
                    >
                    {{"savePassword" | translate}}
                    </button>
                </div>
            </form>
        </ng-container>
    </div>
</div>
