import { Component } from '@angular/core';

@Component({
    selector: 'l-user-create-password-error',
    template: `
        <div class="flex flex-column justify-content-center align-items-center">
            <img
                src="assets/images/create-password-error.svg"
                alt="Illustrator error create password"
            />
            <h2 class="text-center font-bold" style="margin: 20px 0 12px; font-size: 24px">
            {{'ouch!PageIsn’tWorkingRightNow' | translate}}
            </h2>
            <p class="text-center mt-2">
            {{'thisPageDoesNotExist'| translate}}. {{'TheTokenYouRequestedMightBeInvalid' | translate}}
            </p>
        </div>
    `,
})
export class UserCreatePasswordErrorCompoennt {}
