<div class="card grid p-0 m-0">
    <div class="lg:col-6 hide-mobile p-0 m-0 login-bg-left">
        <div class="grid px-4 py-4">
            <!-- <div class="col-6 center-label">
                <img src="assets/logo/krm-logo.svg" />
            </div> -->

            <div class="col-6 center-label">
                <img
                    src="assets/logo/LinovHR.svg"
                    width="85px"
                    height="16.86px"
                />
            </div>
            <!-- <div class="col-12 center-label">
                <img src="assets/logo/LinovHR.svg" width="85px" height="16.86px" />
            </div> -->
        </div>
        <div class="grid" style="margin-top: 4.5rem">
            <div class="col-12">
                <p-carousel
                    [value]="images"
                    [autoplayInterval]="5000"
                    [circular]="true"
                    orientation="none"
                    [numVisible]="1"
                    [numScroll]="1"
                    [responsiveOptions]="responsiveOptions"
                >
                    <ng-template let-item pTemplate="item">
                        <div class="text-center">
                            <img
                                src="{{ item.itemImageSrc }}"
                                [alt]="item.title"
                                width="515px"
                                height="436px"
                            />
                        </div>
                    </ng-template>
                </p-carousel>
            </div>
        </div>
    </div>
    <div class="col-12 lg:col-6 m-0 p-0">
        <form (ngSubmit)="handleSubmit()">
            <div class="container">
                <div class="login-box p-2">
                    <div class="p-fluid mt-4" [formGroup]="formGroup">
                        <span class="p-float-label mb-4 text-left p-title">
                            Login
                        </span>
                        <div class="field">
                            <label
                                for="_username"
                                [ngClass]="{
                                    'ng-dirty ng-invalid':
                                        (formGroup.get('username').touched ||
                                            formGroup.get('username').dirty) &&
                                        !formGroup.get('username').valid
                                }"
                                >Username</label
                            >
                            <div>
                                <input
                                    pInputText
                                    id="_username"
                                    [ngClass]="{
                                        'ng-dirty ng-invalid':
                                            (formGroup.get('username')
                                                .touched ||
                                                formGroup.get('username')
                                                    .dirty) &&
                                            !formGroup.get('username').valid
                                    }"
                                    formControlName="username"
                                    type="text"
                                    placeholder="Input Username"
                                    class="login-input"
                                />
                                <app-error-field-helper
                                    [formControlKey]="formGroup.get('username')"
                                >
                                </app-error-field-helper>
                            </div>
                        </div>

                        <div class="field">
                            <label
                                for="password"
                                [ngClass]="{
                                    'ng-dirty ng-invalid':
                                        (formGroup.get('password').touched ||
                                            formGroup.get('password').dirty) &&
                                        !formGroup.get('password').valid
                                }"
                                >Password</label
                            >
                            <div>
                                <p-password
                                    id="password"
                                    [feedback]="false"
                                    [toggleMask]="true"
                                    formControlName="password"
                                    styleClass="login-password"
                                    [ngClass]="{
                                        'ng-dirty ng-invalid':
                                            (formGroup.get('password')
                                                .touched ||
                                                formGroup.get('password')
                                                    .dirty) &&
                                            !formGroup.get('password').valid
                                    }"
                                    placeholder="Input Password"
                                    autocomplete="on"
                                ></p-password>
                                <app-error-field-helper
                                    [formControlKey]="formGroup.get('password')"
                                >
                                </app-error-field-helper>
                            </div>
                        </div>
                        <div class="grid py-2 align-items-center">
                            <div class="col-6">
                                <p-checkbox
                                    inputId="rememberMe"
                                    formControlName="rememberMe"
                                    [binary]="true"
                                ></p-checkbox>
                                <label class="ml-2" for="rememberMe"
                                    >Remember me</label
                                >
                            </div>
                            <div class="col-6">
                                <span
                                    (click)="navigateToPage('/forgot-password')"
                                    class="p-float-label text-right primary-color"
                                    style="cursor: pointer"
                                >
                                    Forgot Password
                                </span>
                            </div>
                        </div>
                        <span class="p-float-label mt-2">
                            <button
                                pButton
                                pRipple
                                label="Login"
                                [loading]="isLoading"
                            ></button>
                        </span>
                        <span
                            (click)="navigateToPage('/privacy-policy')"
                            class="p-float-label text-center primary-color mt-4"
                            style="cursor: pointer"
                        >
                            Privacy Policy
                        </span>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>

<flash-message-error></flash-message-error>
